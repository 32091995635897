<template>
  <div style="min-height: 100%;background: #EAF7F6">
    <div v-if="!isSubmitOk" class="nps_container mod_column_flex">
      <div class="nps_satisfaction_box">
        <div class="nps_satisfaction_bg"></div>
          <img src="~@/assets/Nps/icon_nps_title.png" alt="图片" class="nps_satisfaction_title"/>
          <p class="nps_satisfaction_welcome">Hi～尊敬的云掌柜用户</p>
          <p class="nps_satisfaction_welcome_content">我们为了给您提供更好的服务，希望您能抽出1分钟时间，将您的感受和建议告诉我们，我们非常愿意听到您的声音，期待您的参与！现在我们就马上开始吧！</p>
          <img src="~@/assets/Nps/icon_nps_yzg.png" alt="图片" class="nps_satisfaction_yzg"/>

          <div class="nps_content_box nps_satisfaction">
            <p class="nps_satisfaction_question" ><span class="mod_color_red">*&nbsp;</span>您的住宿类型</p>
            <div class="mod_grid_column_3 nps_satisfaction_gap">
              <div @click="params.hotel_type = item.value" v-for="item in hotelTypeList" :key="item.value" :class="params.hotel_type === item.value ? 'nps_satisfaction_radio_checked' : 'nps_satisfaction_radio_normal'" class="nps_satisfaction_radio" >
                {{item.text}}
              </div>
            </div>

            <p class="nps_satisfaction_question" ><span class="mod_color_red">*&nbsp;</span>固定位置设有前台</p>
            <div class="mod_grid_column_2 nps_satisfaction_gap">
              <div @click="params.reception = item.value" v-for="item in receptionList" :key="item.value" :class="params.reception === item.value ? 'nps_satisfaction_radio_checked' : 'nps_satisfaction_radio_normal'" class="nps_satisfaction_radio" >
                {{item.text}}
              </div>
            </div>

            <p class="nps_satisfaction_question"><span class="mod_color_red">*&nbsp;</span>您的岗位角色</p>
            <div class="mod_grid_column_3 nps_satisfaction_gap">
              <div @click="params.role_type = item.value" v-for="item in roleTypeList" :key="item.value" :class="params.role_type === item.value ? 'nps_satisfaction_radio_checked' : 'nps_satisfaction_radio_normal'" class="nps_satisfaction_radio" >
                {{item.text}}
              </div>
            </div>

            <p class="nps_satisfaction_question"><span class="mod_color_red">*&nbsp;</span>您有多大可能把云掌柜推荐给朋友或同事？</p>
            <div class="nps_rate">
              <div class="nps_satisfaction_radio"
                   :class="item <= params.score ? 'nps_satisfaction_radio_checked' : 'nps_satisfaction_radio_normal'"
                   @click="params.score = item"
                   v-for="item in rateList" :key="item">
                {{item}}
              </div>
            </div>

            <div class="mod_row_flex mod_justify_between mod_align_item_center">
              <div class="mod_row_flex mod_align_item_center">
                <img src="~@/assets/Nps/icon_nps_unhappy.png" class="nps_rate_emoji"/>
                <span class="nps_rate_text" style="margin-left: 2px">非常不愿意</span>
              </div>
              <div class="mod_row_flex mod_align_item_center">
                <img src="~@/assets/Nps/icon_nps_happy.png" style="margin-right: 2px" class="nps_rate_emoji"/>
                <span class="nps_rate_text">非常愿意</span>
              </div>
            </div>

            <div v-if="params.score !== -1 && params.score <=6">

              <p class="nps_content_scene_title">很抱歉给您带来了不好的体验，请问您觉得哪些方面不太满意呢？</p>
              <div v-for="(item, index) in question1List" :key="index + item.value + 1" class="mod_row_flex mod_align_item_center nps_content_radio_box">
                <van-checkbox v-model="item.checked" @change="onCheckChange($event, item.type, 'question1List')">
                  <template #icon>
                    <img v-if="item.checked" class="nps_content_radio" src="~@/assets/Nps/icon_nps_radio_checked.png" />
                    <img v-else class="nps_content_radio" src="~@/assets/Nps/icon_nps_radio_normal.png" />
                  </template>
                </van-checkbox>
                <div style="margin-left: 8px" class="mod_row_flex mod_align_item_center mod_flex_1">
                  <span class="nps_content_question_text">{{item.value}}. {{item.text}}</span>
                  <van-field v-model="item.inputText"  type="textarea" maxlength="30"  rows="1" autosize placeholder="30字以内简单描述" :border="false" class="mod_flex_1 nps_content_input" v-if="item.type=== 'input' && question1List[question1List.length -1].checked" :adjust-position="true"></van-field>
                </div>
              </div>
            </div>


            <div v-if="params.score !== -1 && params.score > 6" >
              <p class="nps_content_scene_title">感谢您的评分，您愿意推荐我们的主原因是什么？我们还有哪些需要改进的地方？</p>
              <van-field v-model="params.advice" type="textarea" rows="7" class="nps_feedback_input" maxlength="300"
                         :placeholder="'请输入（300字以内）\n如您愿意接受回访，请留下您的手机号'" :adjust-position="true"></van-field>
            </div>
          </div>


        </div>
      <van-button @click="submitNps" :disabled="isButtonDisabled" type="primary" class="nps_submit">提交</van-button>
    </div>

    <div v-else class="mod_column_flex mod_align_item_center nps_success_container">
      <img src="~@/assets/Nps/icon_nps_ok.png" alt="图片" class="nps_success_img"/>
      <p class="nps_success_text">提交成功</p>
      <p class="nps_success_text">非常感谢您的反馈～</p>
      <van-button @click="returnWork" plain hairline type="primary" class="nps_success_button">返回工作台</van-button>
    </div>

    <div v-if="isShowWarning" class="nps_toast_warning mod_column_flex mod_align_item_center">
      <img src="~@/assets/Nps/icon_nps_warning.png" alt="图片" class="nps_warning_img"/>
      <p class="nps_warning_text">感谢参与，您已在其他设备成功提交</p>
    </div>
  </div>
</template>

<script>
import {question1List, hotelTypeList, roleTypeList, receptionList} from "./NpsQuestion";
import {h5BackToApp} from "../../utils/h5AppUtils";
import {addNps} from "../../services/Nps";
import Vue from "vue";
export default {
  name: "NPSIndex",
  data(){
    return{
      rateList: [0,1,2,3,4,5,6,7,8,9,10],
      question1List,
      hotelTypeList,
      roleTypeList,
      receptionList,
      params: {
        score: -1,
        hotel_type: "",
        role_type: "",
        reception: "",
        advice: ""
      },
      isSubmitOk: false,
      isShowWarning: false
    }
  },
  computed:{
    isButtonDisabled(){
      const {params} = this;
      let disabled = true;
      if (params.score !== -1 && params.hotel_type !== "" && params.role_type !== "" && params.reception !== ""){
        disabled = false;
      }
      return disabled;
    }
  },
  methods:{
    submitNps(){
      const {params, question1List} = this;

      const data = {
        ua: "app",
        innid: this.$route.query.innid,
        score: params.score,
        hotel_type: params.hotel_type,
        role_type: params.role_type,
        reception_desk: params.reception
      }
      if (data.score <= 6){
        const question = [];
        question.push({
          question: "1"
        })
        question1List.forEach(item => {
          if (item.checked){
            if (item.type === "radio"){
              question[0][item.value] = item.text;
            }else{
              question[0][item.value] =  item.inputText !== "" ? `其他（${item.inputText }）`: "其他";
            }
          }
        })
        data.question = question;
      }else{
        data.advice = params.advice;
      }
      addNps(data).then((res) => {
        if (res.ret_data.nps_status === "commit_success") {
           this.isSubmitOk = true;
        }else{
          this.isShowWarning = true;
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000)
        }
      })
    },
    onCheckChange(event, type, data){
     if (type === "input" && event){
       Vue.set(this[data][this[data].length - 1], "inputText", "")
       // this[data][this[data].length - 1].inputText = "";
     }
    },
    returnWork(){
      h5BackToApp();
    }
  }
}
</script>

<style scoped lang="less">
.nps_container{
  margin-top: 46px;
  .nps_satisfaction_box{
    height: fit-content;
    position: relative;
    .nps_satisfaction_bg{
      height: 484px;
      background-image: linear-gradient(180deg, #75F8E2 0%, rgba(255,255,255,0.00) 100%);
      box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.20);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
    .nps_satisfaction_title{
      width: 32px;
      height: 24px;
      position: absolute;
      top: 16px;
      left: 20px;
    }
    .nps_satisfaction_welcome{
      height: 24px;
      font-weight: 700;
      font-size: 16px;
      color: #00201B;
      line-height: 24px;
      position: absolute;
      top: 28px;
      left: 20px;
    }
    .nps_satisfaction_welcome_content{
      position: absolute;
      top: 56px;
      left: 20px;
      width: 259px;
      height: 100px;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0,32,27,0.80);
      text-align: justify;
      line-height: 20px;
    }
    .nps_satisfaction_welcome_content_2{
      position: absolute;
      top: 96px;
      left: 20px;
      width: 259px;
      height: 100px;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0,32,27,0.80);
      text-align: justify;
      line-height: 20px;
    }
    .nps_satisfaction_yzg{
      width: 60px;
      height: 88px;
      position: absolute;
      top: 68px;
      right: 16px;
    }
    .nps_satisfaction{
     margin-top: 156px;
     padding-top: 20px;
    }
    .nps_satisfaction_question{
      height: 16px;
      font-weight: 500;
      font-size: 12px;
      color: rgba(0,0,0,0.85);
      line-height: 16px;
      margin-bottom: 8px;
    }
    .nps_satisfaction_gap{
      grid-column-gap: 8px;
      margin-bottom: 20px;
    }
    .nps_satisfaction_radio{
      height: 32px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
    }
    .nps_satisfaction_radio_normal{
      background: rgba(0,0,0,0.04);
      color: rgba(0,0,0,0.65);
    }
    .nps_satisfaction_radio_checked{
      background: #E1FBF1;
      color: #00BE8A;
    }
    .nps_rate{
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      grid-column-gap: 2px;
      margin-bottom: 8px;
    }
    .nps_rate_emoji{
      width: 16px;
      height: 16px;
    }
    .nps_rate_text{
      height: 16px;
      font-weight: 400;
      font-size: 11px;
      color: rgba(0,0,0,0.45);
      line-height: 16px;
    }
  }
  .nps_content_box{
    width: calc(100vw - 16px);
    margin-left: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(117,248,226,0.10);
    border-radius: 8px;
    padding: 0 12px 20px 12px;
    z-index: 1;
    position: relative;
    .nps_content_title{
      width: 240px;
      height: 23px;
    }
    .nps_content_scene_title{
      font-weight: 500;
      font-size: 12px;
      color: rgba(0,0,0,0.85);
      line-height: 16px;
      margin-bottom: 8px;
      margin-top: 20px;

    }
    .nps_content_question_text{
      font-size: 12px;
      font-weight: 400;
    }
    .nps_content_radio_box{
      margin-bottom: 8px;
      min-height: 24px;
      width: 100%;
    }
    .nps_content_radio{
      width: 24px;
      height: 24px;
    }
    .nps_content_input{
      background: rgba(0,0,0,0.04);
      border-radius: 4px;
      padding: 8px 12px;
      margin-left: 8px;
    }
    .nps_feedback_input{
      background: rgba(0,0,0,0.04);
      border-radius: 4px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.nps_submit{
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: calc(constant(safe-area-inset-bottom) + 20px);
  margin-bottom: calc(env(safe-area-inset-bottom) + 20px);
  background: #00BE8A;
}
.nps_success_container{
  height: calc(100vh);
  background-color: white;
  .nps_success_img{
    width: 80px;
    height: 80px;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .nps_success_text{
    font-weight: 400;
    font-size: 17px;
    color: rgba(0,0,0,0.85);
  }
  .nps_success_button{
    border: 1px solid #00BE8A;
    border-radius: 4px;
    width: 160px;
    height: 48px;
    margin-top: 24px;
  }
}
.nps_toast_warning{
  width: 240px;
  height: 160px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2004;
  position: fixed;
  left: 0;
  right: 0;
  margin:auto;
  top: 0;
  bottom: 0;
  .nps_warning_img{
    width: 40px;
    height: 40px;
    margin: 28px 0 16px 0;
  }
  .nps_warning_text{
    width: 192px;
    height: 48px;
    font-weight: 400;
    font-size: 17px;
    color: #FFFFFF;
    text-align: center;
  }
}
/deep/.van-checkbox__icon{
  height: 24px;
}
/deep/.van-checkbox__label{
  width: 100%;
}
/deep/.nps_content_input .van-field__body {
  min-height:16px;
  line-height: 16px;
  input{
    background: transparent;
    min-height:16px;
    border-width: 0px;
    font-size: 12px;
    line-height: 16px;
  }
}
/deep/.nps_content_input .van-field__value{
  min-height:16px;
  line-height: 16px;
}
</style>
