import request from '@/utils/request'
const BASE_URL = process.env.VUE_APP_API_BASE_URL


/**
 * 智能硬件询价表单提交
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addNps(data) {
    return request({
        url: `${BASE_URL}api/v2/user/add_nps`,
        method: 'post',
        data
    })
}
