export const question1List = [
    {
        value: "A",
        type: "radio",
        text: "功能不够齐全",
        checked: false
    },
    {
        value: "B",
        type: "radio",
        text: "系统稳定性较差",
        checked: false
    },
    {
        value: "C",
        type: "radio",
        text: "售后服务效率低",
        checked: false
    },
    {
        value: "D",
        type: "radio",
        text: "产品价格较高",
        checked: false
    },
    {
        value: "E",
        type: "radio",
        text: "界面设计不易操作",
        checked: false,
        inputText: ""
    },
    {
        value: "F",
        type: "input",
        text: "其他",
        checked: false,
        inputText: ""
    },
]

export const hotelTypeList = [
    {
        value: "inn",
        text: "民宿客栈"
    },
    {
        value: "city",
        text: "城市公寓"
    },
    {
        value: "standard",
        text: "标准酒店"
    }
]

export const receptionList = [
    {
        value: "set",
        text: "有"
    },
    {
        value: "unset",
        text: "无"
    }
]


export const roleTypeList = [
    {
        value: "owner",
        text: "老板/业主"
    },
    {
        value: "manager",
        text: "店长/经理"
    },
    {
        value: "sale",
        text: "前台管家"
    }
]
